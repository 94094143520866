import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../layouts/'
import SEO from '../components/Seo'
const IndexPage = ({ location }) => {
  return (
    <Layout>
      <SEO location={location} title="404: Not Found" />
      <div style={{ marginTop: '20vh', marginBottom: '20vh' }}>
        <h1 style={{ textAlign: 'center' }}>Not Found</h1>
        <p style={{ textAlign: 'center' }}>
          You just hit a route that doesn&#39;t exist... the sadness.
        </p>
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object
}

export default IndexPage
